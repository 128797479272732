var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('loading',{attrs:{"active":_vm.isLoading},on:{"update:active":function($event){_vm.isLoading=$event}}}),_c('section',{staticClass:"login-container"},[_c('div',{staticClass:"flex flex-col justify-center items-center"},[_c('RouterLink',{attrs:{"to":{
          name: 'Home',
        }}},[_c('img',{style:(("width: " + (_vm.BRAND_CONFIG.LOGO_SIZE.LOGIN_PAGE.WIDTH) + "px;")),attrs:{"src":_vm.BRAND_CONFIG.ORG_DARK_LOGO,"alt":"Logo"}})]),_c('div',{staticClass:"mt-5 login-area"},[_c('div',{staticClass:"px-12 my-5 w-full"},[_c('div',{staticClass:"h-6 text-center text-red-500"},[_c('transition',{attrs:{"name":"fade"}},[(_vm.reqStatus === 'failed')?_c('div',{staticClass:"text-center text-red-500"},[_vm._v(" Invalid Credentials ")]):_vm._e()])],1),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('ValidationProvider',{attrs:{"name":"username","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('email-field',{model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('input-error-item',{attrs:{"message":errors[0]}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('password-field',{model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('input-error-item',{attrs:{"message":errors[0]}})]}}],null,true)}),_c('div',{staticClass:"py-3 "},[_c('t-button',{staticClass:"w-full bg-color text-primary-color",attrs:{"type":"submit"}},[_vm._v(" Login ")])],1)],1)]}}])})],1),_c('div',{staticClass:" px-12 "},[_c('RouterLink',{attrs:{"to":{
              name: 'ResetPassword',
            }}},[_c('p',{staticClass:"text-center w-full hover:underline "},[_vm._v(" Can't log in? ")])])],1),_c('div',{staticClass:"pb-6 mt-6 px-12 "},[_c('RouterLink',{attrs:{"to":{
              name: 'Register',
            }}},[_c('t-button',{staticClass:"w-full create-btn ",attrs:{"variants":"custom","type":"submit"}},[_vm._v(" Create Account ")])],1)],1)])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }