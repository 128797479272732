<template>
  <div class="flex flex-col mb-4">
    <label class="block">
      <div
        class="mb-3 text-xs font-bold tracking-wide text-gray-900 sm:text-sm"
      >
        Username
      </div>
      <div class="relative">
        <div
          class="absolute top-0 left-0 flex w-10 h-full border border-transparent"
        >
          <div
            class="z-10 flex items-center justify-center w-full h-full text-lg text-gray-600 bg-gray-100 rounded-tl rounded-bl"
          >
            <svg
              class="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
              ></path>
            </svg>
          </div>
        </div>

        <input
          type="text"
          placeholder="Username"
          :value="value"
          @input="$emit('input', $event.target.value)"
          class="relative w-full py-2 pl-12 pr-2 text-sm placeholder-gray-400 border rounded sm:text-base focus:border-gray-800 focus:outline-none"
        />
      </div>
    </label>
  </div>
</template>

<script>
export default {
  name: 'UsernameField',
  props: {
    value: {
      required: false,
    },
  },
}
</script>
